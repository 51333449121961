exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-booknewyear-tsx": () => import("./../../../src/pages/booknewyear.tsx" /* webpackChunkName: "component---src-pages-booknewyear-tsx" */),
  "component---src-pages-bookxmas-tsx": () => import("./../../../src/pages/bookxmas.tsx" /* webpackChunkName: "component---src-pages-bookxmas-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-favourites-tsx": () => import("./../../../src/pages/favourites.tsx" /* webpackChunkName: "component---src-pages-favourites-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-templates-compliance-tsx": () => import("./../../../src/templates/compliance.tsx" /* webpackChunkName: "component---src-templates-compliance-tsx" */)
}

